import React, { useState, useEffect } from 'react';
import _ from 'underscore';
import JBrowseService from '../services/jbrowse.service';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { TextField, Button, Box, MenuItem, Typography } from '@mui/material';
import { Widget, addResponseMessage, toggleWidget } from 'react-chat-widget';
import EventBus from "../common/EventBus";

// Socket.io
import socketIOClient from 'socket.io-client';
import socketUrl from '../common/GetSocket';
import { Redirect } from "react-router-dom";
const ENDPOINT = socketUrl();
// console.log("endpoint: ", ENDPOINT );

function AccessVariantsModal(props) {
    // console.log("props: ", props );
    const { selectedVariants,
        setSelectedVariants,
        selectedAssembly,
        selectedTracks,
        location,
        currentUser,
        sequence,
        setSequence,
        setSequenceModalIsOpen,
        featureModalIsOpen,
        setFeatureModalIsOpen,
        accessVariantIsOpen,
        setAccessVariantIsOpen,
        setHaploData,
        setHaploModalIsOpen,
        setLoading,
        sampleNames,
        setSampleNames,
        setSampleSequenceIsOpen,
        setOpenAiModalIsOpen,
        selectedCrop,
        setConversation,
        setShowLiteratureSearch,
        setToggleLiteratureSearch,
        chromosomes,
        availableAssemblies,
        currentAssembly,
        setIsConversationModalOpen,
    } = props;
    const [currentType, setCurrentType] = useState('');
    const [sequenceBool, setSequenceBool] = useState(false);
    const [clearBool, setClearBool] = useState(false);
    const [accordionOpen, setAccordionOpen] = useState(null); // to track which accordion item is open
    const [localSelectedVariants, setLocalSelectedVariants] = useState([]);
    const [genBank, setGenBank] = useState(null);
    const [currentProject, setCurrentProject] = useState({ vcfs: [{ filePath: '' }] });
    const [showSpinner, setShowSpinner] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [opacity, setOpacity] = useState(0);
    const [openAiError, setOpenAiError ] = useState(false);
    const [sizeWarning, setSizeWarning ] = useState(false);

    const [assembly, setAssembly] = useState('');
    const [chromosome, setChromosome] = useState(chromosomes[0]);
    const [start, setStart] = useState('');
    const [stop, setStop] = useState('');
    const [panGenomes, setPanGenomes ] = useState([]);

    useEffect(() => {
        if (accessVariantIsOpen) {
            setOpacity(100);
        } else {
            setOpacity(0);
        }
    }, [accessVariantIsOpen]);

    useEffect(() => {
        if (selectedVariants) {
            setLocalSelectedVariants(selectedVariants);
        }
    }, [selectedVariants]);

    useEffect(() => {
        const socket = socketIOClient(ENDPOINT);

        socket.on("haploviewer", response => {
            console.log("socket.io results: ", response);
            if (currentUser.email === response.email) {
                console.warn("success!");
                setLoading(false);

                var json = response.result;
                // Combine two arrays into an Object
                var result = [];
                _.each(json.z, function (value, key) {
                    var s = value.toString();
                    s = s.split('.').join("");
                    s = s.split(",");
                    // console.log("s: ", s );
                    var obj = {
                        z: s,
                        text: json.textArray[key],
                        input: ''
                    };
                    result.push(obj);
                });

                // Clone Array for later 
                let data = {
                    x: json.x,
                    y: json.y,
                    z: json.z,
                    combo: result,
                    text: json.textArray,
                    names: json.x.slice()
                }
                console.log("data: ", data);

                setHaploData(data);
                setAccessVariantIsOpen(false)
                setTimeout(() => { setHaploModalIsOpen(true); }, 100);
            }
        });
        // Clean up the connection when component unmounts
        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                setAccessVariantIsOpen(false);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const clearOnClose = () => {
        console.log("clear: ", clearBool);
        // Implement logic to clear on close
        setLocalSelectedVariants([]);
        if (clearBool) {
            setSelectedVariants([]);
        }
        setAccessVariantIsOpen( false );
    };

    const downloadType = (type) => {
        setCurrentType(type);
        // Implement other logic for download type
    };

    const downloadGenBank = () => {
        setCurrentType("GenBank");

        const vcfFiles = selectedTracks.filter(file => file.endsWith('.vcf'));
        const gffFiles = selectedTracks.filter(file => file.endsWith('.gff'));
        const gff3Files = selectedTracks.filter(file => file.endsWith('.gff3'));
        const gffs = gffFiles.concat(gff3Files);

        const obj = {
            vcf: vcfFiles.join(","),
            gff: gffs.join(','),
            ref: selectedAssembly,
            loc: location,
            size: extractDifference(location),
            user: currentUser.email
        };

        console.log("obj: ", obj);
        setGenBank( obj );
    };

    const downloadVariants = () => {
        setShowSpinner(true);

        if (currentType === "GenBank") {
            console.log("genbank: ", genBank);

            if (genBank.size <= 100000) {
                console.log("size fits..");

                // User
                genBank.user = currentUser.email;

                JBrowseService.downloadGenBank(genBank).then(
                    (response) => {
                        console.log("response: ", response);
                        if (response.status === 0) {
                            const filename = `VariantDownload.${currentType}.gbk`;
                            setSuccessMessage('Successfully downloaded data!');
                            setTimeout(() => setSuccessMessage(''), 3000);
                            downloadFile(response.result, filename);
                            setShowSpinner(false);

                        } else {
                            console.warn("genbank error: ", response.data);
                            setErrorMessage('GenBank Request Error');
                            setTimeout(() => setErrorMessage(''), 3000);
                            setShowSpinner(false);
                        }
                    },
                    (error) => {
                        console.log("error: ", error);
                        setErrorMessage('GenBank Request Error');
                        setTimeout(() => setErrorMessage(''), 3000);
                        setShowSpinner(false);
                    }
                )

            } else {
                console.warn("warn user..");
                alert("You selected to large of a region, try again.");
                setShowSpinner(false)
            }

        } else {
            let vcfs = selectedTracks.filter(file => file.endsWith('.vcf'));
            const obj = {
                variants: localSelectedVariants.join(','),
                vcf: vcfs[0],
                type: currentType,
                user: currentUser.email,
                company: currentUser.DirectoryName
            };
            console.log("variants: ", obj);

            if (!obj.variants) {
                setErrorMessage('Variant Warning Message');
                setTimeout(() => setErrorMessage(''), 3000);
                return;
            }

            let size =  extractDifference(location);
            console.log('size: ', size );
            if( size >= 10000 ) {
                console.log("too big..");
                console.warn("warn user..");
                alert("You selected to large of a region, try again.");
                setShowSpinner(false)
            } else {
                // Send Request
                JBrowseService.variantDownload(obj).then(
                    (response) => {
                        console.log("response: ", response);
                        if (response.status === 0) {
    
                            setSuccessMessage('Successfully downloaded data!');
                            setTimeout(() => setSuccessMessage(''), 3000);
    
                            let fileType = response.type;
                            if (fileType === 'Excel') {
                                fileType = "csv";
                                response.result = response.result.replace(/\t/g, ',');
                            } else if (fileType === 'FastPhase') {
                                fileType = "phase.inp";
                            }
    
                            const filename = `VariantDownload.${fileType}`;
                            downloadFile(response.result, filename);
                            setShowSpinner(false);
    
                        } else {
                            setErrorMessage('Variant Error Message');
                            setTimeout(() => setErrorMessage(''), 3000);
                            setShowSpinner(false);
                        }
                    },
                    (error) => {
                        console.log("error: ", error);
                        setShowSpinner(false);
    
                    }
                )
            }

        }

    };

    const retrieveSequence = () => {
        // Implement logic to retrieve sequence
        const vcfFiles = selectedTracks.filter(file => file.endsWith('.vcf'));
        setLoading(true);
        // Form
        var obj = {
            annotated: sequenceBool,
            checked: sequenceBool,
            interval: 1000,
            locations: location,
            reference: selectedAssembly,
            vcf: vcfFiles[0],
            user: currentUser.email,
            company: currentUser.DirectoryName
        };

        console.log('obj: ', obj);
        JBrowseService.sequenceRetrieval(obj).then(
            (response) => {
                console.log("response: ", response);
                setSuccessMessage('Successfully found a sequence!');
                setTimeout(() => setSuccessMessage(''), 3000);
                // console.warn( "got here: ", sequenceBool )
                if (sequenceBool) {
                    // checked
                    var arr = response.result.split('\n');
                    // console.log("arr: ", arr);
                    var sequenceObj = {
                        loc: arr[0],
                        sequence: arr[1]
                    };
                    var retrievalResponse = [sequenceObj];
                    setSequence(retrievalResponse);
                    console.log("after: ", retrievalResponse);
                    // codingSequenceViewer(retrievalResponse[0].sequence, id, title);
                    setAccessVariantIsOpen(false);
                    setTimeout(() => {
                        setSequenceModalIsOpen(true);
                        setLoading(false);
                    }, 100)
                } else {
                    var arr = response.result.split('\n');
                    // console.log("arr: ", arr);

                    var dat = [];
                    _.each(arr, function (value) {
                        dat.push(value);
                    });

                    var samples = [];
                    _.each(dat.slice(0, -1), (value) => {
                        let fix = value.split("\t");
                        console.log('fix: ', fix);
                        var obj = {
                            loc: fix[0],
                            sequence: fix[1]
                        }
                        samples.push(obj);
                    })

                    setSequence(samples);
                    console.log("after: ", samples);
                    setAccessVariantIsOpen(false);
                    setTimeout(() => {
                        setSequenceModalIsOpen(true);
                        setLoading(false);
                    }, 100)
                }

            },
            (error) => {
                console.log("error: ", error);
                setErrorMessage('An unexpected error occurred.');
                setTimeout(() => setErrorMessage(''), 3000);
                setLoading(false);
            }
        )
    };

    const showSelectSamples = () => {
        setLoading(true);
        // Implement logic to show select samples
        const vcfFiles = selectedTracks.filter(file => file.endsWith('.vcf'));

        let obj = {
            file: vcfFiles[0],
            ref: selectedAssembly,
            user: currentUser.email,
            company: currentUser.DirectoryName
        }
        console.log("obj: ", obj);
        // Send Request
        JBrowseService.getSampleNames(obj).then(
            (response) => {
                console.log("samples response: ", response);
                setSampleNames(response.result.output);
                setAccessVariantIsOpen(false)
                setTimeout(() => {
                    setSampleSequenceIsOpen(true);
                    setLoading(false);
                }, 100);
            },
            (error) => {
                console.log('error: ', error);
                setLoading(false);
            }
        )

    };

    const featureTable = () => {
        // Implement logic for feature table
    };

    const clearVariants = () => {
        setSelectedVariants([]);
    };

    const toggleClearVariants = () => {
        console.log("toggle clear variants");
        setClearBool(!clearBool);
    };

    const clearVariantsOnClose = () => {
        // Implement logic to clear variants on close
    };

    const addHighlightedVariants = () => {
        // Implement logic to add highlighted variants
    };

    const removeVariant = (index) => {
        console.log("index: ", index);
        const updatedVariants = [...localSelectedVariants];
        updatedVariants.splice(index, 1);
        setLocalSelectedVariants(updatedVariants);
    }

    const sequenceToggle = () => {
        setSequenceBool(!sequenceBool);
    }

    const openFeatureTableModal = () => {
        setAccessVariantIsOpen(false);
        setTimeout(() => {
            setFeatureModalIsOpen(true)
        }, 100);
    }

    const buildHaploviewer = () => {
        console.log("tracks: ", selectedTracks);
        setLoading(true);
        const vcfFiles = selectedTracks.filter(file => file.endsWith('.vcf'));
        var obj = {
            variants: localSelectedVariants,
            vcf: vcfFiles[0],
            position: location,
            user: currentUser.email,
            company: currentUser.DirectoryName
        };
        console.log("obj: ", obj);
        // Send Request
        JBrowseService.haploviewer(obj).then(
            (response) => {
                console.log("response: ", response);
            },
            (error) => {
                console.log("error: ", error);
                if (error.status === 401) {
                    console.warn("token expired.")
                }
            }
        )
    }

    const promptOpenAi = () => {
        // setAccessVariantIsOpen(false);
        // setTimeout( ()=> {
        //     setOpenAiModalIsOpen( true );
        // }, 250);
        setLoading(true);
        const gffFiles = selectedTracks.filter(file => file.endsWith('.gff'));
        const gff3Files = selectedTracks.filter(file => file.endsWith('.gff3'));
        const gffs = gff3Files.concat( gffFiles );
        
        var obj = {
            location: location,
            crop: selectedCrop,
            assembly: selectedAssembly,
            gff: gffs[0],
            user: currentUser.email,
            company: currentUser.DirectoryName
        }
        console.log("obj: ", obj );
        const { start, stop } = parseLocationString( location );
        let size = stop-start;
        console.log("size: ", size );
        if( size >= 500000) {
            console.log("too big..");
            setLoading(false)
            setSizeWarning( true );
            setTimeout(function() {
                setSizeWarning( false );
            }, 5000);
        } else {
            console.log("send prompt..");
            // Send Request
            JBrowseService.gffOpenAiPrompt( obj ).then(
                (response) => {
                    console.log('openAi response: ', response );
                    setLoading(false);
    
                    toggleWidget();
                    let prompt = "Describe the primary biological function of a gene using the provided list of Gene Ontology (GO) terms for a specific crop: "+selectedCrop+" and assembly:"+selectedAssembly+". Focus on detailing the specific interactions and roles of the gene based on these GO terms. The description should be concise and strictly adhere to the functions indicated by the GO terms without implying the gene's importance or broader biological significance. Use only the most relevant GO terms and discard terms that are generic, ambiguous, or not relevant to the primary biological function. Your analysis should leverage the depth of information in the Go Terms to provide advanced interpretations suitable for an audience well-versed in bioinformatics. The description should be no longer than one short paragraph. The GO terms are: "+response.goTerms
                    let gff_prmopt = "Given these lines from a gff file from crop: Tomato, and Assembley: S_lycopersicum_chromosomes.3.00 go through the 'Attributes' tab and find interesting facts about this region. Your Response should refraim from using generic bioinformatic terms and instead tailor your insights to an advanced scientific audience with a solid understanding of bioinformatics, eschewing basic explanations in favor of more profound, nuanced interpretations that demonstrate a deep comprehension of the subject matter. gff lines: { "+response.gffs+" }"
                    let convo = [
                        {
                            role: "system",
                            content: "You're role is a bioinformatics specalist and you task is to give detail about the agricultural data provided, be precise and concise."
                        },
                        {
                            role: 'user',
                            content: prompt+"\n"+gff_prmopt
                        },
                        {
                            role: "assistant",
                            content: response.result
                        }
                    ];
                    setConversation( convo );
                    addResponseMessage(response.result)
                    setLoading(false);
                    setAccessVariantIsOpen( false );
                    setShowLiteratureSearch( true );
                    setToggleLiteratureSearch( false );
                },
                (error) => {
                    console.log("error: ", error );
                    console.log("message: ", error.response.data );
                    setOpenAiError(true);
                    setTimeout(()=> {
                        setOpenAiError(false)
                    },3000);
                    setLoading(false);
                }
            )
        }
        
    }

    const handleChromosomeChange = (event) => {
        setChromosome(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const vcfFiles = selectedTracks.filter(file => file.endsWith('.vcf'));
        // Send Request
        var obj = {
            starting: start,
            ending: stop,
            chromosome: chromosome,
            vcfs: vcfFiles,
            user: currentUser.email,
            company: currentUser.DirectoryName,
        };
        console.log("obj: ", obj );
         // console.log('obj: ', obj);

        // Send the obj to the backend using axios
        JBrowseService.getVariants(obj).then(
            (response) => {
                console.log("varaints response: ", response);
                // If no Variants were found...
                if (isBlank(response.result)) {
                    console.log("show warning");
                    alert("no variants found.");
                    setLoading(false);
                    return;
                }

                var variants = response.result.split(',');
                // console.log("variants: ", variants );
                setSelectedVariants(variants);
                // setAccessVariantIsOpen(true);
                setLoading(false);
            },
            (error) => {
                console.log("error: ", error);
                if (error.code === "ERR_NETWORK") {
                    console.warn("logout!!!");
                    EventBus.dispatch("logout");
                }
            }
        )
    };

    const getPanGenomes = (event) => {
        event.preventDefault();
        let obj = {
            user: currentUser.email,
            file: currentAssembly.name+".fa",
            crop: selectedCrop,
        };
        console.log("obj: ", obj );
        JBrowseService.getPanGenomes(obj).then(
            (response) => {
                console.log("pan genome response: ", response);
                setPanGenomes( response.result );
                setAccordionOpen(accordionOpen !== 'convert' ? 'convert' : null)
            },
            (error) => {
                console.log("error: ", error);
                if (error.code === "ERR_NETWORK") {
                    console.warn("logout!!!");
                    EventBus.dispatch("logout");
                }
            }
        )
    }

    const convertPositions = (event) => {
        event.preventDefault();
        let obj = {
            assembly: currentAssembly.name+".fa",
            panGenome: assembly,
            position: `${chromosome}:${start}-${stop}`,
            user: currentUser.email,
            company: currentUser.DirectoryName,
        };
        console.log("obj: ", obj );
        // return;
        // Send Request
        JBrowseService.convertPositions( obj ).then(
            ( response ) => {
                console.log("convert response: ", response );
                setIsConversationModalOpen( true );
            }, 
            ( error ) => {
                console.log("error: ", error );
            }
        )
    }


    return (
        <>
            {showSpinner && (
                <div className='loading'></div>
            )}

            <p> Got Here </p>
            {localSelectedVariants.length > 0 ? (
                <div className={`fixed z-10 inset-0 overflow-y-auto flex items-center justify-center transition-opacity duration-900 ease-in-out ${opacity === 100 ? 'opacity-100' : 'opacity-0'}`} style={{ zIndex: "1000" }}>
                    <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center" style={{ zIndex: "1000" }}>
                        <div className="modal-dialog modal-lg w-4/5 bg-white rounded-lg shadow-lg max-h-3/4 overflow-hidden">
                            <div className="modal-content" id="access-variant-height">
                                <div className="modal-header">
                                    <h5 className="modal-title">
                                        <i className="fas fa-key mr-2"></i> Access Variants
                                    </h5>
                                    <button type="button" className="close" onClick={clearOnClose}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body overflow-y-auto" style={{ maxHeight: '50vh' }}>

                                    {/* If No Variants */}
                                    {localSelectedVariants.length === 0 && (
                                        <div>
                                            <p>Highlight a region or right click on a variant to add variants to the list.</p>
                                            <div className="alert alert-warning mt-4 bg-yellow-300">
                                                <div className="flex items-center">
                                                    <strong>Tip: </strong> If you highlight a region first, you can quickly add variants to the list if you just click the yellow button below.
                                                    <img src="img/highlight.png" alt="highlight-image" className="h-6 ml-4" />
                                                </div>
                                            </div>
                                            <p>Retrieve sequence of highlighted area without variants. <button className="text-blue-600 underline" onClick={retrieveSequence}>Click Here</button></p>
                                        </div>
                                    )}

                                    {/* Error/Warning messages */}
                                    <div className="row">
                                        <div className="col-md-12">
                                            {successMessage && (
                                                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                                                    <strong className="font-bold">Success!</strong>
                                                    <span className="block sm:inline"> Successful Retrieval</span>
                                                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                                        <svg className="fill-current h-6 w-6 text-green-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"></path></svg>
                                                    </span>
                                                </div>
                                            )}

                                            {errorMessage && (
                                                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                                    <strong className="font-bold">Error!</strong>
                                                    <span className="block sm:inline"> There was an error retrieving the sequence.</span>
                                                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                                        <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"></path></svg>
                                                    </span>
                                                </div>
                                            )}

                                            {openAiError && (
                                                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                                    <strong className="font-bold">Error!</strong>
                                                    <span className="block sm:inline"> There was an error with the openAi servers, try again later.</span>
                                                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                                        <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"></path></svg>
                                                    </span>
                                                </div>
                                            )}
                                            
                                            {sizeWarning && (
                                                <div className="bg-red-100 border border-orange-400 text-orange-700 px-4 py-3 rounded relative" role="alert">
                                                <strong className="font-bold">Warning!</strong>
                                                <span className="block sm:inline"> Your selected region is too large for efficient AI analysis. For optimal results, please try selecting a smaller region. This ensures quicker and more accurate analysis. If you need guidance on how to select an appropriate region size, please refer to the user manual or help section.</span>
                                                <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                                    <svg className="fill-current h-6 w-6 text-orange-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"></path></svg>
                                                </span>
                                            </div>
                                            )}
                                        </div>
                                    </div>

                                    {/* Found Variants */}
                                    {localSelectedVariants.length > 0 && (
                                        <div className="container mx-auto">
                                            <div className="flex">
                                                {/* Selected Variants */}
                                                <div className="w-1/2">
                                                    <h5 className="text-center mt-5">Selected Variants - {localSelectedVariants.length}</h5>
                                                    <ul className="list-group overflow-auto my-2 mx-2 h-1/2vh">
                                                        {localSelectedVariants.map((variant, index) => (
                                                            <li key={index} className="list-group-item flex flex-wrap justify-between items-center py-2">
                                                                <span className="max-w-4/5 break-words">{variant}</span>
                                                                <span className="badge badge-danger cursor-pointer ml-4 mt-2" onClick={() => removeVariant(index)}>Remove</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                {/* Variant Options */}
                                                <div className="w-1/2">
                                                    <h5 className="text-center mt-5">Variant Options</h5>
                                                    <div className="mt-2 space-y-4">
                                                        {/* AI Serach */}
                                                         <button
                                                            className="w-full p-3 bg-gray-200 rounded hover:bg-gray-300"
                                                            onClick={ promptOpenAi }
                                                        >
                                                            <PersonSearchIcon/> AI Analysis
                                                        </button>
                                                        
                                                        {/* Haploviewer */}
                                                        <button
                                                            className="w-full p-3 bg-gray-200 rounded hover:bg-gray-300"
                                                            onClick={buildHaploviewer}
                                                        >
                                                            <i className="fas fa-th mr-2"></i> Haploviewer
                                                        </button>

                                                        {/* Download File */}
                                                        <button
                                                            className="w-full p-3 bg-gray-200 rounded hover:bg-gray-300"
                                                            onClick={() => setAccordionOpen(accordionOpen !== 'download' ? 'download' : null)}
                                                        >
                                                            <i className="fas fa-download mr-2"></i> Download File
                                                        </button>
                                                        {accordionOpen === 'download' && (
                                                            <div className="p-4 bg-white border rounded">
                                                                {/* Content for Download File */}
                                                                <div className="p-4">
                                                                    <p className="text-center mb-4">File Types</p>
                                                                    {currentType == "" && (
                                                                        <small>first select a type..</small>
                                                                    )}
                                                                    {/* <div className="font-roboto space-y-2">
                                                                        {["VCF", "Excel", "FastPhase", "GenBank"].map(type => (
                                                                            <div
                                                                                key={type}
                                                                                className={`p-2 rounded ${currentType === type ? 'bg-gray-300' : 'bg-gray-100'} hover:bg-gray-200 cursor-pointer`}
                                                                                onClick={() => {
                                                                                    setCurrentType(type);
                                                                                    if (type === "GenBank") {
                                                                                        console.log("got here with genbank");
                                                                                        // Execute your specific function for GenBank here
                                                                                        downloadGenBank();  // assuming you named it downloadGenBank
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <a href="#" className="text-black flex items-center">
                                                                                    <i className="far fa-file mr-2"></i> {type}
                                                                                </a>
                                                                            </div>
                                                                        ))}

                                                                    </div> */}
                                                                 <div className="font-roboto space-y-2">

                                                                        <button
                                                                            className={`p-2 rounded text-left w-full flex items-center ${currentType === 'VCF' ? 'bg-gray-300' : 'bg-gray-100'} hover:bg-gray-200 focus:outline-none focus:bg-gray-300 cursor-pointer`}
                                                                            onClick={() => setCurrentType('VCF')}
                                                                        >
                                                                            <i className="far fa-file mr-2"></i> VCF
                                                                        </button>

                                                                        <button
                                                                            className={`p-2 rounded text-left w-full flex items-center ${currentType === 'Excel' ? 'bg-gray-300' : 'bg-gray-100'} hover:bg-gray-200 focus:outline-none focus:bg-gray-300 cursor-pointer`}
                                                                            onClick={() => setCurrentType('Excel')}
                                                                        >
                                                                            <i className="far fa-file mr-2"></i> Excel
                                                                        </button>

                                                                        <button
                                                                            className={`p-2 rounded text-left w-full flex items-center ${currentType === 'FastPhase' ? 'bg-gray-300' : 'bg-gray-100'} hover:bg-gray-200 focus:outline-none focus:bg-gray-300 cursor-pointer`}
                                                                            onClick={() => setCurrentType('FastPhase')}
                                                                        >
                                                                            <i className="far fa-file mr-2"></i> FastPhase
                                                                        </button>

                                                                        <button
                                                                            className={`p-2 rounded text-left w-full flex items-center ${currentType === 'GenBank' ? 'bg-gray-300' : 'bg-gray-100'} hover:bg-gray-200 focus:outline-none focus:bg-gray-300 cursor-pointer`}
                                                                            onClick={() => {
                                                                                setCurrentType('GenBank');
                                                                                console.log("got here with genbank");
                                                                                downloadGenBank();
                                                                            }}
                                                                        >
                                                                            <i className="far fa-file mr-2"></i> GenBank
                                                                        </button>

                                                                    </div>


                                                                    <p className="mt-4">Type: <strong>{currentType}</strong></p>
                                                                    <button
                                                                        className="mt-2 px-4 py-2 bg-blue-600 hover:bg-blue-900 rounded text-white flex items-center"
                                                                        onClick={downloadVariants}
                                                                    >
                                                                        <i className="fas fa-download mr-2"></i> Download
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        )}

                                                        {/* Retrieve Sequence */}
                                                        <button
                                                            className="w-full p-3 bg-gray-200 rounded hover:bg-gray-300"
                                                            onClick={() => setAccordionOpen(accordionOpen !== 'retrieve' ? 'retrieve' : null)}
                                                        >
                                                            <i className="fas fa-sort-amount-down-alt mr-2"></i> Retrieve Sequence
                                                        </button>
                                                        {accordionOpen === 'retrieve' && (
                                                            <div className="p-4 bg-white border rounded">
                                                                <div>
                                                                    <p className="text-center text-lg mb-4"><i className="fas fa-sort-amount-down-alt mr-2"></i> Retrieve Sequence Form</p>

                                                                    <label className="flex items-center cursor-pointer">
                                                                        <span className="text-sm font-medium mr-3 text-right">Reference Sequence</span>
                                                                        <div className="relative inline-flex items-center mr-2">
                                                                            <input type="checkbox" className="sr-only peer" checked={sequenceBool} onChange={sequenceToggle} />
                                                                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                                        </div>
                                                                        <span className="text-sm font-medium ml-3 text-left">Annotated Sequence</span>
                                                                    </label>


                                                                    <div className="flex justify-center mb-4">
                                                                        <button className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50" onClick={retrieveSequence}>
                                                                            Get Sequence
                                                                        </button>
                                                                    </div>

                                                                    <hr className="mb-4" />

                                                                    <div className="flex flex-col items-center">
                                                                        <p className="text-center mb-2"><i className="fab fa-get-pocket mr-2"></i> Get Sample Specific Sequences</p>
                                                                        <button className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50" onClick={showSelectSamples}>
                                                                            Select Samples
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {/* Feature Table */}
                                                        <button
                                                            className="w-full p-3 bg-gray-200 rounded hover:bg-gray-300"
                                                            onClick={openFeatureTableModal}
                                                        >
                                                            <i className="fas fa-table mr-2"></i> Feature Table
                                                        </button>

                                                        {/* Convert Positions */}
                                                        {/* <button
                                                            className="w-full p-3 bg-gray-200 rounded hover:bg-gray-300"
                                                            onClick={ getPanGenomes }
                                                        >
                                                            <i className="fa fa-arrows-h" style={{marginRight: '3px'}}></i> Convert Positions
                                                        </button>
                                                        {accordionOpen === 'convert' && (
                                                            <div className="p-4 bg-white border rounded">
                                                                <div className="p-4">
                                                                    <Box component="form" noValidate sx={{ mt: 1 }}>
                                                                        <Typography variant="h6">Convert Positions Form</Typography>

                                                                        {currentAssembly && (
                                                                            <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
                                                                                <i>Current Assembly:</i> {currentAssembly.name}.fa
                                                                            </Typography>
                                                                        )}

                                                                        <TextField
                                                                            select
                                                                            label="Assembly"
                                                                            value={assembly}
                                                                            onChange={(e) => setAssembly(e.target.value)}
                                                                            fullWidth
                                                                            margin="normal"
                                                                        >
                                                                            {panGenomes.map((ref) => (
                                                                                <MenuItem key={ref.idReferenceGenome} value={ref.FilePath}>
                                                                                    {ref.Name}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </TextField>

                                                                        <TextField
                                                                            select
                                                                            label="Chromosome"
                                                                            value={chromosome}
                                                                            onChange={(e) => setChromosome(e.target.value)}
                                                                            fullWidth
                                                                            margin="normal"
                                                                        >
                                                                            {chromosomes.map((option) => (
                                                                                <MenuItem key={option} value={option}>
                                                                                    {option}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </TextField>

                                                                        <TextField
                                                                            label="Start"
                                                                            type="number"
                                                                            value={start}
                                                                            onChange={(e) => {
                                                                                const value = parseInt(e.target.value, 10); 
                                                                                if (!isNaN(value) && value >= 0) {
                                                                                    setStart(value);
                                                                                } else if (e.target.value === '') {
                                                                                    setStart('');
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                            margin="normal"
                                                                        />

                                                                        <TextField
                                                                            label="End"
                                                                            type="number"
                                                                            value={stop}
                                                                            onChange={(e) => {
                                                                                const value = parseInt(e.target.value, 10); 
                                                                                if (!isNaN(value) && value >= 0) {
                                                                                    setStop(value); 
                                                                                } else if (e.target.value === '') {
                                                                                    setStop('');
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                            margin="normal"
                                                                        />

                                                                        <Button onClick={convertPositions} type="button" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                                                                            Convert Positions
                                                                        </Button>
                                                                    </Box>

                                                                </div>
                                                            </div>
                                                        )} */}
                                                        
                                                        {/* Clear Variants */}
                                                        <button
                                                            className="w-full p-3 bg-gray-200 rounded hover:bg-gray-300"
                                                            onClick={clearVariants}
                                                        >
                                                            <i className="fas fa-trash mr-2"></i> Clear Variants
                                                        </button>

                                                    </div>

                                                    <div className="mt-6 flex items-center">
                                                        <input
                                                            type="checkbox"
                                                            id="check"
                                                            checked={clearBool}
                                                            onChange={toggleClearVariants}
                                                            className="form-checkbox text-indigo-600 h-4 w-4"
                                                        />
                                                        <label htmlFor="check" className="ml-2">Clear Variants on Close</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="flex justify-end p-4 border-t border-gray-200">
                                    <button
                                        className="flex items-center px-5 py-2 text-gray-600 bg-gray-50 border border-gray-600 rounded-md hover:bg-gray-100 hover:text-gray-700 hover:border-gray-700 transition duration-150 ease-in-out"
                                        onClick={clearOnClose}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ):(
                <div className={`fixed z-10 inset-0 overflow-y-auto flex items-center justify-center transition-opacity duration-900 ease-in-out ${opacity === 100 ? 'opacity-100' : 'opacity-0'}`} style={{ zIndex: "1000" }}>
                    <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center" style={{ zIndex: "1000" }}>
                        <div className="modal-dialog modal-lg w-4/5 bg-white rounded-lg shadow-lg max-h-3/4 overflow-hidden">
                            <div className="modal-content" id="access-variant-height">
                                <div className="modal-header">
                                    <h5 className="modal-title">
                                        <i className="fas fa-key mr-2"></i> Access Variants
                                    </h5>
                                    <button type="button" className="close" onClick={clearOnClose}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body overflow-y-auto" style={{ maxHeight: '50vh' }}>
                                        <Typography variant="h6" style={{ margin: '16px' }}>
                                            Select Your Preferred Region to Explore Available Variants
                                        </Typography>
                                        <Box
                                            component="form"
                                            sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            {chromosomes && chromosomes.length > 0 && (
                                                <TextField
                                                    select
                                                    label="Chromosome"
                                                    value={chromosome}
                                                    onChange={handleChromosomeChange}
                                                    variant="outlined"
                                                    fullWidth
                                                >
                                                    {chromosomes.map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                            <TextField
                                                required
                                                id="start"
                                                label="Start"
                                                name="start"
                                                type="number"
                                                value={start}
                                                onChange={(e) => setStart(e.target.value)}
                                                variant="outlined"
                                                fullWidth
                                            />
                                            <TextField
                                                required
                                                id="stop"
                                                label="Stop"
                                                name="stop"
                                                type="number"
                                                value={stop}
                                                onChange={(e) => setStop(e.target.value)}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </Box>
                                    </div>
                                <div className="flex justify-end p-4 border-t border-gray-200">
                                    <Button className="mr-3" type="button" variant="contained" color="primary" onClick={handleSubmit}>
                                    Access Variants
                                    </Button>
                                    <button
                                        className="flex items-center px-5 py-2 text-gray-600 bg-gray-50 border border-gray-600 rounded-md hover:bg-gray-100 hover:text-gray-700 hover:border-gray-700 transition duration-150 ease-in-out"
                                        onClick={clearOnClose}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </>
    );
}

export default AccessVariantsModal;

function isBlank(str) {
    return (!str || /^\s*$/.test(str));
}

function extractDifference(str) {
    // Extract numbers after the colon
    const numbers = str.split(':').pop();

    // Split numbers by the dash
    const [start, end] = numbers.split('-').map(Number);

    // Return the difference
    return end - start;
}

function downloadFile(data, filename) {
    if (!data) {
        console.error('No data');
        return;
    }

    if (!filename) {
        filename = 'download.json';
    }

    if (typeof data === 'object') {
        data = JSON.stringify(data, undefined, 2);
    }

    var blob = new Blob([data], { type: 'text/json' });

    // FOR IE:

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
    }
    else {
        var e = document.createEvent('MouseEvents'),
            a = document.createElement('a');

        a.download = filename;
        a.href = window.URL.createObjectURL(blob);
        a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
        e.initEvent('click', true, false, window,
            0, 0, 0, 0, 0, false, false, false, false, 0, null);
        a.dispatchEvent(e);
    }
}

function parseLocationString(locationStr) {
    const parts = locationStr.split(/[:\-]/);
    return {
        chromosome: parts[0],
        start: parseInt(parts[1], 10),
        stop: parseInt(parts[2], 10)
    };
}

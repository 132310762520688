import axios from "axios";
import authHeader from "./auth-header";

import URL from '../common/GetUrl';
var url = URL();
const API_URL = url;


const getQuickBuildFiles = (email) => {
    // console.log("from service: ", email );
    return axios
        .post(API_URL + "quickbuild", { email }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
};

const getUser = (email) => {
    // console.log("from service: ", email );
    return axios
        .post(API_URL + "user", { email }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
};

const getPlants = (email) => {
    // console.log("from service: ", email );
    return axios
        .post(API_URL + "plants", { email }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
};

const getAssemblies = (body) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "assemblies", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
};

const getTracks = (body) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "tracks", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
};

const getProjects = (body) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "projects", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
};

const getCompanies = (body) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "company", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
};

const getBamFiles = (body) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "getBamFiles", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
};

const getChromosomes = (body) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "chromosomes", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
};

const getVariants = (body) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "variants", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
};

const downloadGenBank = (body) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "downloadGenBank", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
};

const variantDownload = (body) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "variantDownload", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
};

const sequenceRetrieval = (body) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "sequenceRetrieval", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
};

const buildFeatureTable = (body) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "buildFeatureTable", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
};

const haploviewer = (body) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "haploviewer", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
};

const blastRegion = (body) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "blast", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
};

const destroyBlastDirectory = (body) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "destroy", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
};

const getSampleNames = (body) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "sampleNames", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
};

const sampleSpecificSequence = (body) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "sampleSpecificSequence", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
};

const getNotes = (body) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "getNotes", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
};

const createNote = (body) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "createNote", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
};

const createBatchNotes = (body) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "createBatchNotes", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
};

const editNotes = (body) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "editNotes", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
};

const deleteNotes = (body) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "deleteNotes", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
};

const checkIfFileExists = ( body ) => {
    return axios
        .post(API_URL + "checkIfFileExists", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
}

const saveSession = ( body ) => {
    return axios
        .post(API_URL + "saveSession", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
}

const getSavedSessions = ( body ) => {
    return axios
        .post(API_URL + "getSavedSessions", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
}

const deleteSession = ( body ) => {
    return axios
        .post(API_URL + "deleteSession", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
}

const changePassword = ( body ) => {
    return axios
        .post(API_URL + "changePassword", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
}

const getHaplotypeScore = ( body ) => {
    return axios
        .post(API_URL + "getHaplotypeScore", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
}

const promptOpenAi = ( body ) => {
    return axios
        .post(API_URL + "promptOpenAi", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
}

const gffOpenAiPrompt = ( body ) => {
    return axios
        .post(API_URL + "gffOpenAiPrompt", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
}

const aiFeaturePrompt = ( body ) => {
    return axios
        .post(API_URL + "aiFeaturePrompt", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
}

const literatureSearch = ( body ) => {
    return axios
        .post(API_URL + "literatureSearch", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
}

const scholarSearch = ( body ) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "scholarSearch", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
}

const updateSessionForUser = ( body ) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "updateSessionForUser", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
}

const convertPositions = ( body ) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "convertPositions", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
}

const getPanGenomes = ( body ) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "getPanGenomes", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
}

const blastSequence = ( body ) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "blastSequence", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
}

const aiConversation = ( body ) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "aiConversation", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
}

const getPafFiles = ( body ) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "getPafFiles", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
}

const testing = ( body ) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "testing", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
}
const getAvailableBamFiles = ( body ) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "getAvailableBamFiles", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
}

const alignmentViewer = ( body ) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "alignmentViewer", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
}

const getLocalBamFiles = ( body ) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "getLocalBamFiles", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
}

const gffClickPrompt = ( body ) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "gffClickPrompt", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
}

const getBigWigTracks = ( body ) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "getBigWigTracks", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
}

const variantPrompt = ( body ) => {
    // console.log("from service: ", body );
    return axios
        .post(API_URL + "variantPrompt", { body }, { headers: authHeader(), withCredentials: true  })
        .then((response) => {
            return response.data;
        });
}




const JBrowseService = {
    getUser,
    getPlants,
    getAssemblies,
    getTracks,
    getProjects,
    getCompanies,
    getBamFiles,
    getChromosomes,
    getVariants,
    downloadGenBank,
    variantDownload,
    sequenceRetrieval,
    buildFeatureTable,
    haploviewer,
    blastRegion,
    destroyBlastDirectory,
    getSampleNames,
    sampleSpecificSequence,
    getNotes,
    createNote,
    createBatchNotes,
    editNotes,
    deleteNotes,
    checkIfFileExists,
    saveSession,
    getSavedSessions,
    deleteSession,
    changePassword,
    getHaplotypeScore,
    promptOpenAi,
    gffOpenAiPrompt,
    aiFeaturePrompt,
    literatureSearch,
    scholarSearch,
    updateSessionForUser,
    convertPositions,
    getPanGenomes,
    blastSequence,
    aiConversation,
    getPafFiles,
    testing,
    getQuickBuildFiles,
    getAvailableBamFiles,
    alignmentViewer,
    getLocalBamFiles,
    gffClickPrompt,
    getBigWigTracks,
    variantPrompt,
};

export default JBrowseService;
